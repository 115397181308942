@import '~ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.flex-center-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
}

html{
    scroll-behavior: smooth;
}

body {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-style: normal;
}

.img {
    max-width: 100%;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.color {
    color: white!important;
}

.cursor {
    cursor: pointer!important;
}

.margenes-clientes {
    margin-top: 20px;
}


.fondo{
    background: white!important;
}

a,
.button {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

a:focus,
.button:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

a:focus {
    color: #fff;
    text-decoration: none;
}

a,
button {
    color: #1F1F1F;
    outline: medium none;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Roboto", sans-serif;
    color: #4A5252;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}

ul {
    margin: 0px;
    padding: 0px;
}

li {
    list-style: none;
}

p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #000000;
    margin-bottom: 13px;
    font-family: "Roboto", sans-serif;
}

.parrafo {
    font-size: 17px!important;
    font-weight: 400!important;
    line-height: 28px!important;
    color: #ffffff!important;
    margin-bottom: 13px;
    font-family: "Roboto", sans-serif;
}

label {
    color: #7e7e7e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

*::-moz-selection {
    background: #291073;
    /* color: #fff; */
    text-shadow: none;
}

::-moz-selection {
    background: #291073;
    /* color: #fff; */
    text-shadow: none;
}

::selection {
    background: #291073;
    color: #fff;
    text-shadow: none;
}

*::-webkit-input-placeholder {
    /* color: #cccccc; */
    font-size: 14px;
    opacity: 1;
}

*:-ms-input-placeholder {
    color: #cccccc;
    font-size: 14px;
    opacity: 1;
}

*::-ms-input-placeholder {
    /* color: #cccccc; */
    font-size: 14px;
    opacity: 1;
}

*::placeholder {
    /* color: #cccccc; */
    font-size: 14px;
    opacity: 1;
}

h3 {
    font-size: 24px;
}

.mb-65 {
    margin-bottom: 67px;
}

.black-bg {
    background: #020c26 !important;
}

.white-bg {
    background: #ffffff;
}

.gray-bg {
    background: #f5f5f5;
}

.overlay {
    position: relative;
    z-index: 0;
}

.cuadrado {
    font-size: 16px!important;
    text-align: justify!important;
    color: black!important;
    font-weight: 500!important;
}

.overlay::before {
    position: absolute;
    content: "";
    background-color: #1f1f1f;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: .5;
}

.overlay2 {
    position: relative;
    z-index: 0;
}

.overlay2::before {
    position: absolute;
    content: "";
    background-color: #000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
}

.overlay_skyblue {
    position: relative;
    z-index: 0;
}

.overlay_skyblue::before {
    position: absolute;
    content: "";
    /* background: linear-gradient(120deg, #89f7fe 0%, #66a6ff 10%); */
    background-image: linear-gradient(to top, #002A60 0%, #6f86d6 100%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.8;
}

.overlay_skyblack {
    position: relative;
    z-index: 0;
}

.clienteimagen{
    height: 200px!important;
    width:  450px!important;
}

.overlay_skyblack::before {
    position: absolute;
    content: "";
    /* background-image: url(../src/assets/img/banner/2.PNG); */
    background-image: linear-gradient(50deg, #D6231B 0%, #D6231B 100%);
    /* background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%); */
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
}

.bradcam_overlay {
    position: relative;
    z-index: 0;
}

.bradcam_overlay_galeria {
    position: relative;
    z-index: 0;
}

.bradcam_overlay_proyecto {
    position: relative;
    z-index: 0;
}

.bradcam_overlay::before {
    position: absolute;
    content: "";
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5db2ff+0,7db9e8+100&1+24,0+96 */
    background: -moz-linear-gradient(left, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#5db2ff', endColorstr='#007db9e8', GradientType=1);
    /* IE6-9 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
}

.bradcam_overlay_galeria::before {
    position: absolute;
    content: "";
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5db2ff+0,7db9e8+100&1+24,0+96 */
    background: -moz-linear-gradient(left, #D6241C 0%, #D6241C 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #D6241C 0%, #D6241C 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #D6241C 0%, #D6241C 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#D6241C', endColorstr='#007db9e8', GradientType=1);
    /* IE6-9 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
}

.bradcam_overlay_proyecto::before {
    position: absolute;
    content: "";
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5db2ff+0,7db9e8+100&1+24,0+96 */
    background: -moz-linear-gradient(left, #002A60 0%, #002A60 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #002A60 0%, #002A60 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #002A60 0%, #002A60 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#002A60', endColorstr='#007db9e8', GradientType=1);
    /* IE6-9 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
}

.section-padding {
    padding-top: 120px;
    padding-bottom: 120px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 7));
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 7));
    }
}

.slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 150px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 960px;
}

.slider::before,
.slider::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(150px * 6);
}

.slider .slide {
    height: 100px;
    width: 250px;
}

@media only screen and (max-width: 992px) {
    .slider {
        box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
        height: 100px;
        margin: auto;
        overflow: hidden;
        position: relative;
        width: 960px;
    }
    .slider .slide-track {
        animation: scroll 40s linear infinite;
        display: flex;
        width: calc(150px * 6);
    }
    .slider {
        height: 150px;
        width: auto;
    }
}

@media (max-width: 767px) {
    .slider {
        height: 150px;
        width: auto;
    }
}


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .slider {
        height: 100px;
        width: auto;
    }
}

@media only screen and (min-width: 400px) {
    .slider {
        height: 100px;
        width: auto;
    }
}

@media only screen and (max-width: 400px) {
    .slider {
        height: 100px;
        width: auto;
    }
}


/* button style */

.owl-carousel .owl-nav div {
    background: transparent;
    height: 50px;
    left: 0px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 50px;
    color: #fff;
    background-color: transparent;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    left: 50px;
    font-size: 15px;
    line-height: 50px;
    border: 1px solid #fff;
    left: 140px;
}

.mb-20px {
    margin-bottom: 20px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-40 {
    margin-bottom: 40px;
}

.boxed-btn3 {
    background: #667eea;
    /* Old browsers */
    background: -moz-linear-gradient(120deg, #667eea 0%, #764ba2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(120deg, #667eea 0%, #764ba2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#667eea', endColorstr='#764ba2', GradientType=1);
    /* IE6-9 */
    color: #fff;
    display: inline-block;
    padding: 18px 44px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 700;
    border: 0;
    border: 1px solid transparent;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 20px;
}

.boxed-btn3:hover {
    background: #fff;
    color: #002A60 !important;
    border: 1px solid #002A60;
    transition: all 0.4s ease-in-out;
}

.boxed-btn3:focus {
    outline: none;
}

.boxed-btn3.large-width {
    width: 220px;
}

.boxed-btn3-white {
    color: #fff;
    display: inline-block;
    padding: 9px 24px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    border: 0;
    border: 1px solid #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.boxed-btn3-white:hover {
    background: #fff;
    color: #009DFF !important;
    border: 1px solid transparent;
}

.boxed-btn3-white:focus {
    outline: none;
}

.boxed-btn3-white.large-width {
    width: 220px;
}

.boxed-btn3-white-2 {
    color: #5DB2FF !important;
    display: inline-block;
    padding: 9px 24px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    border: 0;
    border: 1px solid #5DB2FF;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    text-transform: capitalize;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.boxed-btn3-white-3 {
    color: #fff !important;
    background: #002A60;
    display: inline-block;
    padding: 9px 24px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    border: 0;
    /* border: 1px solid #5DB2FF; */
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    text-transform: capitalize;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.boxed-btn3-white-2:hover {
    background: #5DB2FF;
    color: #fff !important;
    border: 1px solid transparent;
}

.boxed-btn3-white-3:hover {
    background: linear-gradient(250deg, #89f7fe 0%, #66a6ff 10%);
    color: #fff !important;
    /* border: 1px solid #291073; */
    transition: all 0.4s ease-in-out !important;
}

.boxed-btn3-white-2:focus {
    outline: none;
}

.boxed-btn3-white-3:focus {
    outline: none;
}

.boxed-btn3-white-2.large-width {
    width: 220px;
}

.boxed-btn3-white-3.large-width {
    width: 220px;
}

.boxed-btn2 {
    background: transparent;
    color: #fff;
    display: inline-block;
    padding: 18px 24px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.boxed-btn2:hover {
    background: #fff;
    color: #131313 !important;
}

.boxed-btn2:focus {
    outline: none;
}

.line-button {
    color: #919191;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding-right: 5px;
    padding-bottom: 2px;
}

.line-button::before {
    position: absolute;
    content: "";
    background: #919191;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
}

.line-button:hover {
    color: #009DFF;
}

.line-button:hover::before {
    background: #009DFF;
}

.book_now {
    display: inline-block;
    font-size: 14px;
    color: #009DFF;
    border: 1px solid #009DFF;
    text-transform: capitalize;
    padding: 10px 25px;
}

.book_now:hover {
    background: #009DFF;
    color: #fff;
}

.section_title h3 {
    font-size: 36px;
    font-weight: 500;
    line-height: 48px;
    color: #1F1F1F;
    position: relative;
    z-index: 9;
    padding-bottom: 15px;
}

.section_title h3::before {
    position: absolute;
    left: 0;
    background: #5DB2FF;
    height: 2px;
    width: 50px;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

@media (max-width: 767px) {
    .section_title h3 {
        font-size: 30px;
        line-height: 36px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section_title h3 {
        font-size: 36px;
        line-height: 42px;
    }
}

@media (max-width: 767px) {
    .section_title h3 br {
        display: none;
    }
}

.section_title p {
    font-size: 16px;
    color: #727272;
    line-height: 28px;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .section_title p br {
        display: none;
    }
}

.mb-100 {
    margin-bottom: 100px;
}

@media (max-width: 767px) {
    .mb-100 {
        margin-bottom: 40px;
    }
}

@media (max-width: 767px) {
    .mobile_menu {
        position: absolute;
        right: 0px;
        width: 100%;
        z-index: 9;
    }
}

.slicknav_menu .slicknav_nav {
    background: #fff;
    float: right;
    margin-top: 0;
    padding: 0;
    width: 95%;
    padding: 0;
    border-radius: 0px;
    margin-top: 5px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 11px;
}

.slicknav_menu .slicknav_nav a:hover {
    background: transparent;
    color: #009DFF;
}

.slicknav_menu .slicknav_nav a.active {
    color: #009DFF;
}

@media (max-width: 767px) {
    .slicknav_menu .slicknav_nav a i {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .slicknav_menu .slicknav_nav a i {
        display: none;
    }
}

.slicknav_menu .slicknav_nav .slicknav_btn {
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: -40px;
    position: relative;
    z-index: 99;
    border: 1px solid #ddd;
    top: 3px;
    right: 5px;
    top: -36px;
}

.slicknav_menu .slicknav_nav .slicknav_btn .slicknav_icon {
    margin-right: 6px;
    margin-top: 3px;
    position: relative;
    padding-bottom: 3px;
    top: -11px;
    right: -5px;
}

@media (max-width: 767px) {
    .slicknav_menu {
        margin-right: 0px;
    }
}

.slicknav_nav .slicknav_arrow {
    float: right;
    font-size: 22px;
    position: relative;
    top: -9px;
}

.slicknav_btn {
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    z-index: 99;
    border: none;
    border-radius: 3px;
    top: 5px;
    padding: 5px;
    right: 5px;
    margin-top: -5px;
    top: -31px;
}

.slicknav_btn {
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    z-index: 99;
    border: none;
    border-radius: 3px;
    top: 5px;
    padding: 5px;
    right: 0;
    margin-top: -5px;
    top: -36px;
}

.header-area {
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 9;
}

@media (max-width: 767px) {
    .header-area {
        padding-top: 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area {
        padding-top: 0;
    }
}

.header-area .main-header-area {
    padding: 18px 0;
    background: #fff;
}

.header-area .main-header-area .logo-img {
    text-align: center;
}

@media (max-width: 767px) {
    .header-area .main-header-area .logo-img {
        text-align: left;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area .logo-img {
        text-align: left;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area .logo-img {
        text-align: left;
    }
}

@media (max-width: 767px) {
    .header-area .main-header-area .logo-img img {
        width: 70px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area .logo-img img {
        width: 70px;
    }
}

.header-area .main-header-area .Appointment {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
}

.header-area .main-header-area .Appointment .socail_links ul li {
    display: inline-block;
}

.header-area .main-header-area .Appointment .socail_links ul li a {
    color: #fff;
    margin: 0 10px;
    font-size: 15px;
}

.header-area .main-header-area .Appointment .socail_links ul li a:hover {
    color: #fff;
}

.header-area .main-header-area .Appointment .book_btn {
    margin-left: 30px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area .Appointment .book_btn {
        margin-left: 0;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .header-area .main-header-area .Appointment .book_btn {
        margin-left: 0;
    }
}

.header-area .main-header-area .Appointment .book_btn a {
    background: #009DFF;
    padding: 12px 26px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area .Appointment .book_btn a {
        padding: 12px 20px;
    }
}

.header-area .main-header-area .Appointment .book_btn a:hover {
    background: #fff;
    color: #009DFF;
    border: 1px solid #009DFF;
}

.header-area .main-header-area .main-menu {
    text-align: right;
    padding: 12px 0;
}

.header-area .main-header-area .main-menu ul li {
    display: inline-block;
    /* text-align: right; */
    position: relative;
    margin: 0 10px;
}

.header-area .main-header-area .main-menu ul li a {
    color: #002A60;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
    padding: 0px 0px 0px 0px;
    font-family: "Roboto", sans-serif;
    position: relative;
    letter-spacing: 1px;
    text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area .main-menu ul li a {
        font-size: 15px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .header-area .main-header-area .main-menu ul li a {
        font-size: 15px;
    }
}

.header-area .main-header-area .main-menu ul li a i {
    font-size: 9px;
}

@media (max-width: 767px) {
    .header-area .main-header-area .main-menu ul li a i {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area .main-menu ul li a i {
        display: none !important;
    }
}

.header-area .main-header-area .main-menu ul li a::before {
    position: absolute;
    content: "";
    background: #fff;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scaleX(0);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.header-area .main-header-area .main-menu ul li a:hover::before {
    opacity: 1;
    transform: scaleX(1);
}

.header-area .main-header-area .main-menu ul li a.active::before {
    opacity: 1;
    transform: scaleX(1);
}

.header-area .main-header-area .main-menu ul li a:hover {
    color: #1F1F1F;
}

.header-area .main-header-area .main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 160%;
    background: #fff;
    width: 200px;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
    opacity: 0;
    visibility: hidden;
    text-align: left;
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.header-area .main-header-area .main-menu ul li .submenu li {
    display: block;
}

.header-area .main-header-area .main-menu ul li .submenu li a {
    padding: 10px 15px;
    position: inherit;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    display: block;
    color: #000;
}

.header-area .main-header-area .main-menu ul li .submenu li a::before {
    display: none;
}

.header-area .main-header-area .main-menu ul li .submenu li:hover a {
    color: #000;
}

.header-area .main-header-area .main-menu ul li:hover>.submenu {
    opacity: 1;
    visibility: visible;
    top: 150%;
}

.header-area .main-header-area .main-menu ul li:hover>a::before {
    opacity: 1;
    transform: scaleX(1);
}

.header-area .main-header-area .main-menu ul li:first-child a {
    padding-left: 0;
}

.header-area .main-header-area.sticky {
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    z-index: 99;
    transform: translateY(70px);
    transition: transform 500ms ease, background 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.96);
}

@media (max-width: 767px) {
    .header-area .main-header-area.sticky {
        padding: 10px 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area.sticky {
        padding: 10px 10px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area.sticky {
        padding: 10px 20px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .header-area .main-header-area.sticky {
        padding: 10px 20px;
    }
}

.header-area .main-header-area.sticky .main-menu {
    padding: 0;
}

.header-area .header-top_area {
    padding: 12px 0;
    background: rgba(214, 35, 27, .78);
}

@media (max-width: 767px) {
    .header-area .header-top_area .social_media_links {
        text-align: center;
    }
}

.header-area .header-top_area .social_media_links a {
    font-size: 15px;
    color: #ffffff;
    margin-right: 12px;
}

.header-area .header-top_area .social_media_links a:hover {
    color: #002A60;
}

.header-area .header-top_area .short_contact_list {
    text-align: right;
}

@media (max-width: 767px) {
    .header-area .header-top_area .short_contact_list {
        text-align: center;
    }
}

.header-area .header-top_area .short_contact_list ul li {
    display: inline-block;
}

.header-area .header-top_area .short_contact_list ul li a {
    font-size: 13px;
    color: #ffffff;
    margin-left: 15px;
}

@media (max-width: 767px) {
    .header-area .header-top_area .short_contact_list ul li a {
        margin-left: 0;
        margin: 0 5px;
    }
}

.header-area .header-top_area .short_contact_list ul li a i {
    color: #5DB2FF;
    margin-right: 7px;
}

.slider_bg_1 {
    /* background-image: url(../src/assets/img/banner/banner.webp); */
    background-image: url(../src/assets/img/Rubenia.jpg);
    margin-bottom: 20px;
}

.slider_bg_3 {
    /* background-image: url(../src/assets/img/banner/pexels-pixabay-268362.jpg); */
    margin-bottom: 20px;
    margin-top: 20px!important;
}

.margenes {
    margin-top: 50px;
}

.slider_area .single_slider {
    height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (max-width: 767px) {
    .slider_area .single_slider {
        height: 420px;
    }
}

.slider_area .single_slider .slider_text h3 {
    color: #1F1F1F;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    /* text-transform: capitalize; */
    letter-spacing: 2px;
    font-weight: 300;
}

.slider_area .single_slider .slider_text h3 span {
    font-weight: 700;
}

@media (max-width: 767px) {
    .slider_area .single_slider .slider_text h3 {
        font-size: 33px;
        letter-spacing: 3px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .slider_area .single_slider .slider_text h3 {
        font-size: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .slider_area .single_slider .slider_text h3 {
        font-size: 35px;
        letter-spacing: 3px;
    }
}

.slider_area .single_slider .slider_text p {
    font-size: 18px;
    font-weight: 400;
    color: #727272;
    margin-bottom: 40px;
    margin-top: 10px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .slider_area .single_slider .slider_text p {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .slider_area .single_slider .slider_text p {
        font-size: 16px;
    }
}

.welcome_docmed_area {
    padding-top: 120px;
    padding-bottom: 220px;
}

.welcome_docmed_area_2 {
    padding-top: 120px;
    padding-bottom: 20px;
}

.forma {
    font-size: 35px!important;
    font-weight: 700!important;
    margin-top: 80px;
}

@media (max-width: 767px) {
    .welcome_docmed_area {
        padding-bottom: 100px;
    }
}

@media (max-width: 767px) {
    .thumb_1 img {
        width: 100%;
    }
}

@media (min-width: 767px) {
    .thumb_1 img {
        width: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .welcome_docmed_area {
        padding-bottom: 100px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .welcome_docmed_area {
        padding-bottom: 100px;
    }
}

.welcome_docmed_area .welcome_thumb {
    position: relative;
}

.welcome_docmed_area .welcome_thumb .thumb_1 {
    left: 0;
}

.welcome_docmed_area .welcome_thumb .thumb_1 img {
    width: 60%;
}

@media (max-width: 767px) {
    .welcome_docmed_area .welcome_thumb .thumb_1 img {
        width: 100%;
    }
}

.welcome_docmed_area .welcome_thumb .thumb_2 {
    position: absolute;
    bottom: -104px;
    right: -20px;
}

@media (max-width: 767px) {
    .welcome_docmed_area .welcome_thumb .thumb_2 {
        position: absolute;
        left: 0;
        top: 0;
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .welcome_docmed_area .welcome_thumb .thumb_2 {
        position: absolute;
        right: 0;
        bottom: -40px;
    }
}

.welcome_docmed_area .welcome_thumb .thumb_2 img {
    width: 89%;
}

@media (min-width: 768px) and (max-width: 991px) {
    .welcome_docmed_area .welcome_thumb .thumb_2 img {
        width: 100%;
    }
}

.welcome_docmed_area .welcome_docmed_info {
    padding-left: 90px;
}

@media (max-width: 767px) {
    .welcome_docmed_area .welcome_docmed_info {
        padding-left: 0;
        margin-top: 40px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .welcome_docmed_area .welcome_docmed_info {
        padding-left: 0;
        margin-top: 100px;
    }
}

.welcome_docmed_area .welcome_docmed_info h2 {
    font-size: 15px;
    font-weight: 400;
    color: #1F1F1F;
    margin-bottom: 40px;
    position: relative;
    z-index: 9;
    padding-bottom: 9px;
}

.welcome_docmed_area .welcome_docmed_info h2:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
    background: #5DB2FF;
}

.welcome_docmed_area .welcome_docmed_informacion h2 {
  font-size: 30px;
  font-weight: 400;
  color: #1F1F1F;
  margin-bottom: 40px;
  position: relative;
  z-index: 9;
  padding-bottom: 9px;
}

.welcome_docmed_area .welcome_docmed_informacion h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: #5DB2FF;
}

.welcome_docmed_area .welcome_docmed_info h3 {
    font-weight: 500;
    font-size: 36px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .welcome_docmed_area .welcome_docmed_info h3 {
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    .welcome_docmed_area .welcome_docmed_info h3 br {
        display: none;
    }
}

.welcome_docmed_area .welcome_docmed_info p {
    font-size: 19px;
    color: #727272;
    line-height: 28px;
    text-align: justify;
}

.welcome_docmed_area .welcome_docmed_informacion p {
  font-size: 19px;
  color: #727272;
  line-height: 28px;
  text-align: justify;
}

.welcome_docmed_area .welcome_docmed_info ul {
    margin-bottom: 40px;
    margin-top: 25px;
}

.welcome_docmed_area .welcome_docmed_info ul li {
    color: #727272;
    font-size: 16px;
    line-height: 28px;
}

.welcome_docmed_area .welcome_docmed_informacion ul li {
  color: #727272;
  font-size: 18px;
  line-height: 28px;
}

.welcome_docmed_area .welcome_docmed_info ul li i {
    font-size: 23px;
    margin-right: 10px;
    color: #5DB2FF;
    font-weight: 16px;
    padding-top: 30px;
}

.welcome_docmed_area .welcome_docmed_informacion ul li i {
  font-size: 35px;
  margin-right: 10px;
  color: #5DB2FF;
  font-weight: 16px;
  padding-top: 30px;
}

.business_expert_area {
    padding-bottom: 100px;
}

@media (max-width: 767px) {
    .business_expert_area {
        padding-bottom: 50px;
    }
}

.business_expert_area .business_info .icon i {
    background: #5DB2FF;
    width: 75px;
    height: 75px;
    display: inline-block;
    color: #fff;
    font-size: 35px;
    text-align: center;
    line-height: 75px;
    border-radius: 50%;
}

.business_expert_area .business_info h3 {
    font-size: 20px;
    font-weight: 500;
    color: #1F1F1F;
    margin-top: 16px;
    margin-bottom: 14px;
}

.business_expert_area .business_info p {
    color: #727272;
    line-height: 28px;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .business_expert_area .business_thumb {
        padding-top: 20px;
    }
}

.business_expert_area .business_thumb img {
    width: 100%;
}

.business_expert_area .business_tabs_area {
    background: #F5FBFF;
}

.business_expert_area .business_tabs_area .nav .nav-item {
    width: 33.3333333334%;
}

@media (max-width: 767px) {
    .business_expert_area .business_tabs_area .nav .nav-item {
        width: 100%;
    }
}

.business_expert_area .business_tabs_area .nav .nav-item a {
    font-size: 20px;
    font-weight: 400;
    color: #1F1F1F;
    padding: 35px 0px 35px 40px;
}

@media (max-width: 767px) {
    .business_expert_area .business_tabs_area .nav .nav-item a {
        padding: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .business_expert_area .business_tabs_area .nav .nav-item a {
        padding: 20px;
        font-size: 15px;
    }
}

.business_expert_area .business_tabs_area .nav .nav-item a.active {
    background: #fff;
}

.business_expert_area .business_tabs_area #myTab {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.border_bottom {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 60px;
    padding-top: 60px;
}

.footer {
    background-repeat: no-repeat;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer .footer_top {
    padding-top: 145px;
    padding-bottom: 129px;
    background-image: url(../src/assets/img/banner/Fondo_Footer.webp);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

@media (max-width: 767px) {
    .footer .footer_top {
        padding-top: 60px;
        padding-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .footer .footer_top .footer_widget {
        margin-bottom: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer .footer_top .footer_widget {
        margin-bottom: 30px;
    }
}

.footer .footer_top .footer_widget .footer_title {
    font-size: 22px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .footer .footer_top .footer_widget .footer_title {
        margin-bottom: 20px;
    }
}

.footer .footer_top .footer_widget .footer_logo {
    font-size: 22px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .footer .footer_top .footer_widget .footer_logo {
        margin-bottom: 20px;
    }
}

.footer .footer_top .footer_widget p {
    color: #C7C7C7;
}

.footer .footer_top .footer_widget p.footer_text {
    font-size: 16px;
    color: #B2B2B2;
    margin-bottom: 23px;
    font-weight: 400;
    line-height: 28px;
}

.footer .footer_top .footer_widget p.footer_text a.domain {
    color: #B2B2B2;
    font-weight: 400;
}

.footer .footer_top .footer_widget p.footer_text a.domain:hover {
    color: #5DB2FF;
    border-bottom: 1px solid #5DB2FF;
}

.footer .footer_top .footer_widget p.footer_text.doanar a {
    font-weight: 500;
    color: #B2B2B2;
}

.footer .footer_top .footer_widget p.footer_text.doanar a:hover {
    color: #5DB2FF;
    border-bottom: 1px solid #5DB2FF;
}

.footer .footer_top .footer_widget p.footer_text.doanar a.first {
    margin-bottom: 10px;
}

.footer .footer_top .footer_widget ul li a {
    font-size: 16px;
    color: #BABABA;
    line-height: 42px;
}

.footer .footer_top .footer_widget ul li a:hover {
    color: #5DB2FF;
}

.footer .footer_top .footer_widget .newsletter_form {
    position: relative;
    margin-bottom: 20px;
}

.footer .footer_top .footer_widget .newsletter_form input {
    width: 100%;
    height: 45px;
    background: #fff;
    padding-left: 20px;
    font-size: 16px;
    color: #000;
    border: none;
}

.footer .footer_top .footer_widget .newsletter_form input::placeholder {
    font-size: 16px;
    color: #919191;
}

.footer .footer_top .footer_widget .newsletter_form button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border: none;
    font-size: 14px;
    color: #fff;
    background: #5DB2FF;
    padding: 10px;
    padding: 0 22px;
    cursor: pointer;
}

.footer .footer_top .footer_widget .newsletter_text {
    font-size: 16px;
    color: #BABABA;
}

.footer .copy-right_text {
    padding-bottom: 30px;
    background: #000000;
}

.footer .copy-right_text .footer_border {
    padding-bottom: 30px;
}

.footer .copy-right_text .copy_right {
    font-size: 16px;
    color: #919191;
    margin-bottom: 0;
    font-weight: 400;
}

@media (max-width: 767px) {
    .footer .copy-right_text .copy_right {
        font-size: 14px;
    }
}

.footer .copy-right_text .copy_right a {
    color: #5DB2FF;
}

.footer .socail_links {
    margin-top: 47px;
}

@media (max-width: 767px) {
    .footer .socail_links {
        margin-top: 30px;
    }
}

.footer .socail_links ul li {
    display: inline-block;
}

.footer .socail_links ul li a {
    font-size: 18px;
    color: #C3B2F0;
    line-height: 42px;
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    background: #707070;
    border-radius: 4px;
    line-height: 50px !important;
    margin-right: 7px;
    color: #FFFFFF;
}

.footer .socail_links ul li a:hover {
    color: #fff !important;
}

.bradcam_area {
    padding: 170px;
    background-size: cover;
    background-position: center center;
    padding: 148px 0px;
    background-repeat: no-repeat;
}

.bradcam_area_galeria {
    padding: 170px;
    background-size: cover;
    background-position: center center;
    padding: 58px 0px;
    background-repeat: no-repeat;
}

.bradcam_area_proyecto {
    padding: 170px;
    background-size: cover;
    background-position: center center;
    padding: 58px 0px;
    background-repeat: no-repeat;
}

@media (max-width: 767px) {
    .bradcam_area {
        padding: 120px 0;
    }
    .bradcam_area_galeria {
        padding: 80px 0;
    }
    .bradcam_area_proyecto {
        padding: 80px 0;
    }
}

.bradcam_area h3 {
    font-size: 50px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: capitalize;
}

.bradcam_area_galeria h3 {
    font-size: 50px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: capitalize;
}

.bradcam_area_proyecto h3 {
    font-size: 50px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .bradcam_area h3 {
        font-size: 30px;
    }
    .bradcam_area_galeria h3 {
        font-size: 30px;
    }
    .bradcam_area_proyecto h3 {
        font-size: 30px;
    }
}

.bradcam_area p {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    text-transform: capitalize;
}

.bradcam_area p a {
    color: #fff;
}

.bradcam_area p a:hover {
    color: #fff;
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #000000;
    opacity: .7;
}

.our_department_area {
    background: #F5FBFF;
    padding-top: 120px;
    padding-bottom: 90px;
}

.our_department_area_2 {
    /* background: #F5FBFF; */
    padding-top: 120px;
    padding-bottom: 90px;
}

.cliente_area {
    background: #F5FBFF;
    padding-top: 50px;
    padding-bottom: 90px;
    text-align: center;
}

.our_department_area .single_department {
    background: #fff;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0 6px 10px rgba(0, 0, 0, 0.04);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.04);
}

.our_department_area .single_department .department_thumb {
    overflow: hidden;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.our_department_area .single_department .department_thumb img {
    width: 150%;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.our_department_area .single_department .department_content {
    padding: 22px 30px 24px 30px;
}

.our_department_area .single_department .department_content h3 {
    margin-bottom: 0;
}

.our_department_area .single_department .department_content h3 a {
    font-size: 15px;
    color: #1F1F1F;
    line-height: 33px;
    font-weight: 500;
}

.our_department_area .single_department .department_content h3 a:hover {
    color: #5DB2FF;
}

.our_department_area .single_department .department_content p {
    font-size: 16px;
    line-height: 28px;
    color: #727272;
    margin-top: 5px;
    margin-bottom: 10px;
}

.our_department_area .single_department .department_content a.learn_more {
    color: #5DB2FF;
    font-size: 16px;
}

.our_department_area .single_department .department_content a.learn_more:hover {
    text-decoration: underline;
}

.our_department_area .single_department:hover .department_thumb img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.service_area {
    background-image: url(../src/assets/img/banner/servicios.webp);
    background-repeat: no-repeat;
    background-position: center top;
}

@media (min-width: 330px) and (max-width: 500px) {
  .service_area{
      background-repeat: repeat;
      color: black!important;
  }
}

.service_area .single_service {
    padding: 40px 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .service_area .single_service {
        padding: 40px 20px;
    }
}

.service_area .single_service .icon {
    margin-bottom: 10px;
}

.service_area .single_service .icon i {
    font-size: 50px;
    color: #fff;
}

.service_area .single_service h3 {
    font-size: 26px;
    font-weight: 500;
    color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
    .service_area .single_service h3 {
        font-size: 20px;
    }
}

.service_area .single_service p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .service_area .single_service .boxed-btn3-white {
        padding: 5px 10px;
        margin-bottom: 10px;
    }
}

.service_area .col-xl-4:nth-child(2) .single_service {}

.Emergency_contact .single_emergency {
    padding: 91px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media (max-width: 767px) {
    .Emergency_contact .single_emergency {
        padding: 40px;
        display: block !important;
    }
}

.Emergency_contact .single_emergency .info {
    margin-right: 30px;
    text-align: center;
}

.Emergency_contact .single_emergency .info h3 {
    font-size: 36px;
    font-weight: 700;
    color: #111;
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .Emergency_contact .single_emergency .info h3 {
        font-size: 20px;
    }
}

.Emergency_contact .single_emergency .info p {
    color: #111;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
}

.Emergency_contact .single_emergency .info_button a {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}


/* .emergency_bg_2 {
    background-image: url(../src/assets/img/banner/vision.svg);
} */

.contact-title {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px;
}

.form-contact label {
    font-size: 14px;
}

.form-contact .form-group {
    margin-bottom: 30px;
}

.form-contact .form-control {
    border: 1px solid #e5e6e9;
    border-radius: 0px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent;
}

.form-contact .form-control:focus {
    outline: 0;
    box-shadow: none;
}

.form-contact .form-control::placeholder {
    font-weight: 300;
    color: #999999;
}

.form-contact textarea {
    border-radius: 0px;
    height: 100% !important;
}
